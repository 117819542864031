import { Card, CardContent, Typography } from "@mui/material"
import { Avatar, Grid, MenuItem, Select, Stack, TextField } from "@northvolt/ui"
import { i18n } from "i18next"
import { useTranslation } from "react-i18next"
import { useLocalStorage } from "usehooks-ts"

type ProfileProps = {
  user: {
    firstName: string
    lastName: string
    email: string
    tenant: {
      displayName: string
    }
    // currentLanguage: "en-GB" | "ge-DE"
  }
  i18n: i18n
}

export const Profile = ({ user, i18n }: ProfileProps) => {
  const { t } = useTranslation("components")
  const [currentLanguage, setCurrentLanguage] = useLocalStorage("language", "en-GB")

  const initials = `${user.firstName[0]}${user.lastName[0]}`

  const supportedLanguges = [
    {
      lang: "en-GB",
      displayName: t(`Profile.languages.en-GB`),
    },
  ]
  if (user.tenant.displayName === "Northvolt") {
    supportedLanguges.push({
      lang: "de-DE",
      displayName: t(`Profile.languages.de-DE`),
    })
  }

  return (
    <>
      <Typography variant="headlineMedium" mb={4}>
        {t("Profile.title")}
      </Typography>
      <Card>
        <CardContent sx={{ padding: 4 }}>
          <Stack direction="row" alignItems={"center"} mb={4}>
            <Avatar sx={{ width: 64, height: 64 }}>{initials}</Avatar>
            <Typography variant="subtitleMedium" ml={2}>
              {user.firstName} {user.lastName}
            </Typography>
          </Stack>

          <Typography variant="headlineSmall" mb={3}>
            {t("Profile.details")}
          </Typography>
          <Grid container spacing={4} mb={4}>
            <Grid sm={12} md={6}>
              <TextField label={t("Profile.firstName")} value={user.firstName} fullWidth disabled />
            </Grid>
            <Grid sm={12} md={6}>
              <TextField label={t("Profile.lastName")} value={user.lastName} fullWidth disabled />
            </Grid>
            <Grid sm={12} md={6}>
              <TextField
                label={t("Profile.company")}
                value={user.tenant.displayName}
                fullWidth
                disabled
              />
            </Grid>
            <Grid sm={12} md={6}>
              <TextField label={t("Profile.email")} value={user.email} fullWidth disabled />
            </Grid>
          </Grid>
          <Typography variant="headlineSmall">{t("Profile.languageTitle")}</Typography>
          <Typography variant="subtitleMedium" mb={3}>
            {t("Profile.languageDescription")}
          </Typography>
          <Grid container spacing={2} mb={4}>
            <Grid sm={12} md={6}>
              <Select
                fullWidth
                defaultValue={currentLanguage}
                variant="outlined"
                sx={{
                  minWidth: "300px",
                }}
                onChange={(e) => {
                  setCurrentLanguage(e.target.value as string)
                  i18n.changeLanguage(e.target.value as string)
                }}
              >
                {supportedLanguges.map((lang) => (
                  <MenuItem value={lang.lang}>{lang.displayName}</MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}
