import { Grid } from "@northvolt/ui"
import { createFileRoute } from "@tanstack/react-router"
import { JSX, useContext } from "react"
import { GroupSummaryList } from "../components/PickupOrder/Components/GroupSummaryList/GroupSummaryList"
import { OrderContext } from "./pickup-orders.$orderId.$stepId"

const Base = (): JSX.Element => {
  const { order } = useContext(OrderContext)

  const { stepId, groupId } = Route.useParams()
  if (!order) return <div>Oups we dont have an order. Shouldnt be here</div>

  return (
    <Grid container spacing={2}>
      <Grid xs={12} md={6}>
        {/* <AddAnotherPackage orderId={order.pickupOrder.id} /> */}
      </Grid>
      <Grid xs={12} md={6}>
        <GroupSummaryList order={order.pickupOrder} groupId={groupId} stepId={stepId} />
      </Grid>
    </Grid>
  )
}

export const Route = createFileRoute("/pickup-orders/$orderId/$stepId/groups/$groupId/")({
  component: Base,
})
