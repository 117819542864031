import { jwtDecode } from "jwt-decode"
import { JSX, createContext, useContext, useEffect, useMemo, useState } from "react"

export const isWindow = (): boolean => typeof window !== "undefined"

export type User = {
  email: string
  firstName: string
  lastName: string
  fullName: string
  roles: string[]
  tenant: string
  title: string
  isAdmin: boolean
  department: string
  idToken: string
}

export type AuthenticationProps = {
  cookieBaseName: string
  cognitoUserPoolWebClientId: string
  cognitoOauthUri: string
  redirectURL: string
  approvedRoles?: string[]
  children?: JSX.Element | JSX.Element[]
  loadingComponent?: JSX.Element | JSX.Element[]
  onErrorComponent?: JSX.Element | JSX.Element[]
  onMissingRoleComponent?: JSX.Element | JSX.Element[]
}

export type AuthenticationJWTPayload = {
  exp: number
  "custom:department": string
  "custom:jobtitle": string
  "custom:office": string
  "custom:roles": string
  "custom:tenant": string
  email: string
  family_name: string
  given_name: string
  name: string
}

export type AuthenticationContextType = {
  user: User
  idToken?: string
  error?: string
}

type Payload = Record<string, string>

const emptyUser: User = {
  email: "",
  firstName: "",
  lastName: "",
  fullName: "",
  roles: [],
  tenant: "",
  title: "",
  isAdmin: false,
  department: "",
  idToken: "",
}

// We're providing a default user here to avoid having to deal with undefined user in the context
// This will never be displayed to the user since we'll never render the children if the user is undefined
export const AuthenticationContext = createContext<AuthenticationContextType>({
  user: emptyUser,
})

function getCookie(cookieName: string): string | null {
  const nameEQ = `${cookieName}=`
  const cookies = document.cookie.split(";")

  // TODO this really shouldn't be a for loop
  // TODO this really shouldn't use a while loop
  // eslint-disable-next-line no-restricted-syntax
  for (let cookie of cookies) {
    while (cookie.charAt(0) === " ") {
      cookie = cookie.substring(1, cookie.length)
    }

    if (cookie.indexOf(nameEQ) === 0) {
      return cookie.substring(nameEQ.length, cookie.length)
    }
  }

  return null
}

function setCookie(name: string, value: string, expiration: Date, domain: string): string {
  // We don't care about security on localhost
  const cookieSecurity = domain !== "localhost"

  try {
    const cookies =
      // eslint-disable-next-line
      (document.cookie = `${name}=${value};expires=${expiration};domain=${domain};path=/;${cookieSecurity}`)

    return cookies
  } catch (error) {
    throw new Error("user is logged out")
  }
}

function getCookieDomain(domainStr: string): string {
  const domain = new URL(domainStr)
  const urlPartials = domain.host.split(":")[0].split(".")
  // This is localhost, we don't want to prefix it with a . as it will make the cookie invalid
  if (urlPartials.length === 1) {
    return urlPartials[0]
  }

  // Strip any potential port number
  return domain.host.split(":")[0]
}

async function postFormData(
  endpoint: string,
  payload: Payload,
): Promise<{
  access_token: string
  id_token: string
}> {
  const formData: string = Object.keys(payload)
    .map((key) => {
      if (isWindow()) {
        return `${window.encodeURIComponent(key)}=${window.encodeURIComponent(payload[key])}`
      }
      return false
    })
    .filter(Boolean)
    .join("&")

  return fetch(endpoint, {
    body: formData,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    method: "POST",
  })
    .then((response) => {
      return response.json()
    })
    .then((responseData) => {
      if (responseData.error) {
        throw responseData.error
      }

      return responseData
    })
}

async function refreshToken(
  redirectURL: string,
  cookieBaseName: string,
  cognitoOauthUri: string,
  cognitoUserPoolWebClientId: string,
): Promise<string> {
  const refreshTokenCookie = getCookie(`${cookieBaseName}_refresh`)
  if (!refreshTokenCookie) {
    throw new Error("user is logged out")
  }

  const payload: Payload = {
    client_id: cognitoUserPoolWebClientId,
    grant_type: "refresh_token",
    refresh_token: refreshTokenCookie,
  }
  const { access_token: accessToken, id_token: idToken } = await postFormData(
    `${cognitoOauthUri}/oauth2/token`,
    payload,
  )

  if (!idToken) {
    throw new Error("missing payload from refreshToken, login is no longer valid")
  }

  const expirationDate = new Date()

  expirationDate.setMonth(expirationDate.getMonth() + 1)

  const idTokenExpirationdate = new Date()

  idTokenExpirationdate.setHours(idTokenExpirationdate.getHours() + 1)

  const cookieDomain = getCookieDomain(redirectURL)

  // TODO what is access token used for? Is it actually needed?
  setCookie(`${cookieBaseName}_access`, accessToken, expirationDate, cookieDomain)
  setCookie(`${cookieBaseName}_id`, idToken, idTokenExpirationdate, cookieDomain)

  return idToken
}

async function authenticate(
  redirectURL: string,
  cookieBaseName: string,
  cognitoOauthUri: string,
  cognitoUserPoolWebClientId: string,
): Promise<{ newIdToken: string; newUser: User }> {
  if (!cookieBaseName) {
    throw new Error("CookieBaseName is not defined")
  }

  let idToken = getCookie(`${cookieBaseName}_id`)

  if (!idToken) {
    idToken = await refreshToken(
      redirectURL,
      cookieBaseName,
      cognitoOauthUri,
      cognitoUserPoolWebClientId,
    )
  }

  const jwtPayload: AuthenticationJWTPayload = jwtDecode(idToken)
  const hasExpired = Date.now() >= jwtPayload.exp * 1000

  if (hasExpired) {
    // TODO: with a new token from refreshToken this should never happen? Or at the very least we should refresh instead of throwing an error
    throw new Error("Token expired")
  }

  const { email, name, given_name: firstName, family_name: lastName } = jwtPayload

  const user: User = {
    email: email || name,
    idToken,
    firstName,
    lastName,
    fullName: `${firstName} ${lastName}`,
    roles: jwtPayload["custom:roles"].split(" "),
    tenant: jwtPayload["custom:tenant"],
    title: jwtPayload["custom:jobtitle"],
    isAdmin: jwtPayload["custom:roles"].includes("root"),
    department: jwtPayload["custom:department"],
  }

  return { newIdToken: idToken, newUser: user }
}

export const AuthProvider = (props: AuthenticationProps): JSX.Element => {
  const {
    children,
    cookieBaseName,
    redirectURL,
    cognitoUserPoolWebClientId,
    cognitoOauthUri,
    approvedRoles,
    loadingComponent,
    onErrorComponent,
    onMissingRoleComponent,
  } = props

  if (!cookieBaseName) {
    throw new Error("<Authentication requires cookieBaseName")
  }

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [user, setUser] = useState<User>(emptyUser)
  const [idToken, setIdToken] = useState<string>("")
  const [authenticateError, setAuthenticateError] = useState<string>("")

  useEffect(() => {
    authenticate(redirectURL, cookieBaseName, cognitoOauthUri, cognitoUserPoolWebClientId)
      .then(({ newUser, newIdToken }) => {
        setUser(newUser)
        setIdToken(newIdToken)
        setIsLoading(false)
      })
      .catch((error) => {
        if (redirectURL) {
          window?.location?.replace(
            `${redirectURL}?redirect_url=${encodeURIComponent(`${window?.location?.href}`)}`,
          )
        }

        setAuthenticateError(error.message || "Authentication Error")
        setUser(emptyUser)
        setIdToken("")
        setIsLoading(false)
      })
  }, [cognitoOauthUri, cognitoUserPoolWebClientId, cookieBaseName, redirectURL])

  useEffect(() => {
    const intervalId = setInterval(() => {
      refreshToken(redirectURL, cookieBaseName, cognitoOauthUri, cognitoUserPoolWebClientId)
    }, 60_000)

    return () => clearInterval(intervalId)
  }, [cognitoOauthUri, cognitoUserPoolWebClientId, cookieBaseName, redirectURL])

  const value = useMemo(
    () => ({ user, idToken, error: authenticateError }),
    [user, idToken, authenticateError],
  )

  if (isLoading) {
    return <>{loadingComponent || <p>Loading...</p>}</>
  }

  if (!user || !idToken) {
    return <>{onErrorComponent || <p>{authenticateError}</p>}</>
  }

  if (
    approvedRoles &&
    approvedRoles.length > 0 &&
    !approvedRoles.some((role) => user.roles.includes(role))
  ) {
    return <>{onMissingRoleComponent || <p>Missing role</p>}</>
  }

  return <AuthenticationContext.Provider value={value}>{children}</AuthenticationContext.Provider>
}

export const useIdToken = (): string | undefined => {
  const context = useContext(AuthenticationContext)
  if (context === undefined) {
    throw new Error("useIdToken must be used within a AuthProvider")
  }

  return context.idToken
}

export const useUser = (): User => {
  const context = useContext(AuthenticationContext)
  if (context === undefined) {
    throw new Error("useUser must be used within a AuthProvider")
  }

  return context.user
}

export const logout = (cookieBaseName: string, cookieDomain: string): void => {
  setCookie(`${cookieBaseName}_id`, "", new Date(0), cookieDomain)
  setCookie(`${cookieBaseName}_access`, "", new Date(0), cookieDomain)
  setCookie(`${cookieBaseName}_refresh`, "", new Date(0), cookieDomain)

  window.location.replace("/")
}
