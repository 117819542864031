import { SubmittedModal } from "@components/PickupOrder/Components/SubmittedModal/SubmittedModal"
import { IntroSmallVolumeStep } from "@components/PickupOrder/Steps/IntroSmallVolumeStep/IntroSmallVolumeStep"
import { IntroStep } from "@components/PickupOrder/Steps/IntroStep/IntroStep"
import { PickupOrderFlow, TenantFragment, usePickupOrderQuery, useTenantQuery } from "@gen/graphql"
import { createFileRoute } from "@tanstack/react-router"
import { JSX } from "react"

/*
 * This is the main entry point for the pickup order flow.
 * It will determine which step to render based on the tenant configuration.
 * The first step is determined by the first element in the pickupOrderFlow array.
 * Once we move past the first flow we must have a created order to continue.
 */
const PickupIndexComponent = (): JSX.Element => {
  const { submittedOrderId } = Route.useSearch<{ submittedOrderId: string }>()
  const { data: tenant } = useTenantQuery()

  const { data } = usePickupOrderQuery({
    variables: {
      id: submittedOrderId,
    },
    skip: !submittedOrderId,
  })

  return (
    <>
      {data?.pickupOrder && <SubmittedModal order={data?.pickupOrder} />}
      {tenant?.tenant && firstStep(tenant?.tenant)}
    </>
  )
}

export const Route = createFileRoute("/pickup-orders/")({
  component: PickupIndexComponent,
})

const firstStep = (tenant: TenantFragment): JSX.Element => {
  switch (tenant.pickupOrderFlow[0]) {
    case PickupOrderFlow.Intro:
      return <IntroStep />
    case PickupOrderFlow.IntroSmallVolume:
      return <IntroSmallVolumeStep />
    default:
      return <div>Missing pickup order flow</div>
  }
}
