/* eslint-disable no-param-reassign */
import {
  BoxFragment,
  BoxTypeFragment,
  ModuleTypeFragment,
  OrderFragment,
  OrderStatus,
  useUpdateModuleMutation,
} from "@gen/graphql"
import { zodResolver } from "@hookform/resolvers/zod"
import {
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  OutlinedInput,
  SelectChangeEvent,
} from "@mui/material"
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Chip,
  ListItemText,
  Stack,
  Typography,
  useSnackbar,
} from "@northvolt/ui"
import { FormSelect } from "@northvolt/ui/Form"
import { CardActions, IconBox } from "@shared"
import { readableEnum } from "@shared"
import { useNavigate } from "@tanstack/react-router"
import { JSX, useEffect } from "react"
import { DefaultValues, FormProvider, SubmitHandler, useFieldArray, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { z } from "zod"
import { moduleSchema } from "../AddBox/AddModule"
import { Module } from "../Module/Module"

type EditModuleProps = {
  stepId: string
  order: OrderFragment
  box: BoxFragment
  moduleTypes: ModuleTypeFragment[]
  boxTypes: BoxTypeFragment[]
}

export const EditModule = ({
  stepId,
  order,
  box,
  moduleTypes,
  boxTypes,
}: EditModuleProps): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const updateSnackbar = useSnackbar()
  const [updateModule, { error }] = useUpdateModuleMutation()
  const formSchema = z.object({
    boxTypeId: z.string().refine((id) => boxTypes.some((p) => p.id === id)),
    modules: z.array(moduleSchema),
  })

  useEffect(() => {
    if (error?.message) {
      updateSnackbar({
        message: error.message,
        severity: "error",
      })
    }
  }, [error, updateSnackbar])

  type ZodInputTypes = z.infer<typeof formSchema>
  const defaultValues: Required<DefaultValues<ZodInputTypes>> = {
    boxTypeId: box.boxType.id,
    modules: box.items.map((m) => {
      if (m.__typename !== "Module") {
        throw new Error("Invalid module type")
      }
      return {
        moduleId: m.id,
        moduleTypeId: m.moduleType.id,
        displayName: m.moduleType.displayName,
        netWeight: m.netWeight,
        green: m.green,
        yellow: m.yellow,
        red: m.red,
      }
    }),
  }
  const methods = useForm<ZodInputTypes>({
    resolver: zodResolver(formSchema),
    defaultValues,
  })
  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitSuccessful, isSubmitting },
  } = methods

  const { fields, append, remove } = useFieldArray({
    control,
    name: "modules",
  })

  useEffect(() => {
    if (box) {
      reset({
        boxTypeId: box.boxType.id,
        modules: box.items.map((m) => {
          if (m.__typename !== "Module") {
            throw new Error("Invalid module type")
          }
          return {
            moduleId: m.id,
            moduleTypeId: m.moduleType.id,
            displayName: m.moduleType.displayName,
            netWeight: m.netWeight,
            green: m.green,
            yellow: m.yellow,
            red: m.red,
          }
        }),
      })
    }
  }, [box, reset])

  const handleChange = (event: SelectChangeEvent<string[]>): void => {
    const {
      target: { value },
    } = event
    const removedModules = fields.filter((m) => !value.includes(m.moduleTypeId))
    removedModules.forEach((v) => {
      const removeIndex = fields.findIndex((f) => f.moduleTypeId === v.moduleTypeId)
      if (removeIndex !== -1) {
        remove(removeIndex)
      }
    })

    if (typeof value === "object") {
      value.forEach((newId) => {
        const existingIndex = fields.findIndex((m) => m.moduleTypeId === newId)
        if (existingIndex === -1) {
          const moduleType = moduleTypes.find((m) => m.id === newId)
          if (moduleType !== undefined) {
            append({
              moduleId: newId,
              moduleTypeId: moduleType?.id,
              displayName: moduleType?.displayName ?? "",
              netWeight: 0,
              green: 0,
              yellow: 0,
              red: 0,
            })
          }
        }
      })
    }
  }
  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  }

  const onSubmit: SubmitHandler<ZodInputTypes> = (data: ZodInputTypes): Promise<void> => {
    return updateModule({
      variables: {
        input: {
          etag: order.etag,
          pickupOrderId: order.id,
          boxId: box.id,
          updates: {
            boxTypeId: data.boxTypeId,
            modules: data.modules.map((m) => ({
              moduleType: {
                internalID: m.moduleTypeId,
                displayName: m.displayName,
              },
              moduleId: m.moduleId,
              netWeight: m.netWeight,
              green: m.green,
              yellow: m.yellow,
              red: m.red,
            })),
          },
        },
      },
    }).then(() => {
      navigate({
        to: "/pickup-orders/$orderId/$stepId",
        params: {
          orderId: order.id,
          stepId: stepId,
        },
      })
    })
  }

  return (
    <FormProvider {...methods}>
      <Card>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <CardContent>
            <CardActions>
              <Typography variant="headlineSmall">
                <IconBox /> {box.boxType.displayName}
              </Typography>
              <Stack direction="row" gap={2}>
                <Button
                  variant="text"
                  onClick={() => {
                    navigate({
                      to: "/pickup-orders/$orderId/$stepId",
                      params: {
                        orderId: order.id,
                        stepId: stepId,
                      },
                    })
                  }}
                >
                  {t("components.basics.cancel")}
                </Button>
                <Button
                  data-testid={
                    isSubmitSuccessful
                      ? "edit-module-submit-button-success"
                      : "edit-module-submit-button"
                  }
                  disabled={
                    isSubmitting ||
                    (order.status !== OrderStatus.Draft && order.status !== OrderStatus.Pending)
                  }
                  type="submit"
                >
                  {isSubmitSuccessful ? t("components.basics.saved") : t("components.basics.save")}
                </Button>
              </Stack>
            </CardActions>
            <Stack spacing={2}>
              <FormSelect
                control={control}
                fullWidth
                name="boxTypeId"
                label={t("components.basics.selectBoxType.label")}
                sx={{
                  minWidth: "300px",
                }}
                options={boxTypes.map((p) => ({
                  value: p.id,
                  label: p.displayName,
                }))}
              />
              <Box>
                <InputLabel>Choose module type</InputLabel>
                <MuiSelect
                  multiple
                  fullWidth
                  value={fields.map((m) => m.moduleTypeId)}
                  renderValue={(selected) => {
                    return (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selected.map((value) => {
                          const moduleType = moduleTypes.find((m) => m.id === value)
                          return (
                            <Chip
                              key={value}
                              label={readableEnum(moduleType?.displayName ?? "unknown")}
                            />
                          )
                        })}
                      </Box>
                    )
                  }}
                  onChange={handleChange}
                  input={<OutlinedInput label="Chose module type" />}
                  MenuProps={MenuProps}
                >
                  {moduleTypes.map((m) => (
                    <MenuItem key={m.id} value={m.id}>
                      <Checkbox
                        checked={fields.find((f) => f.moduleTypeId === m.id) !== undefined}
                      />
                      <ListItemText primary={readableEnum(m.displayName)} />
                    </MenuItem>
                  ))}
                </MuiSelect>
              </Box>

              {fields.map((module, index) => (
                <Module key={module.id} index={index} module={module} />
              ))}
            </Stack>
          </CardContent>
        </form>
      </Card>
    </FormProvider>
  )
}
