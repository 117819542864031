/* eslint-disable no-param-reassign */
import {
  BatteryStatus,
  BoxTypeFragment,
  OrderFragment,
  PackTypeFragment,
  useAddPackMutation,
} from "@gen/graphql"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "@lib/i18n"
import { zodEnum } from "@lib/zod"
import { InputAdornment } from "@mui/material"
import {
  Alert,
  Box,
  Button,
  CardContent,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@northvolt/ui"
import {
  AutoCompleteInput,
  BatteryStatusLabel,
  CancelButton,
  CardActions,
  IconCarBattery,
  IconPlus,
  NumberInput,
  NumberSelect,
} from "@shared"
import { readableEnum } from "@shared"
import { JSX, useEffect } from "react"
import { Controller, DefaultValues, FormProvider, SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

// This schema defines the possible values in the pack form
// eslint-disable-next-line @typescript-eslint/no-unused-vars, unused-imports/no-unused-vars
const packSchema = z.object({
  packTypeId: z.string(),
  netWeight: z.number().min(1),
  batteryStatus: z.enum(zodEnum(Object.values(BatteryStatus))),
  copies: z.coerce.number().positive(),
})

// This schema defines the constrains that need to be true for it to be possible to submit the form
const requiredSchema = z.object({
  packTypeId: z.string().min(1),
  netWeight: z.number().min(1),
  batteryStatus: z.enum(zodEnum(Object.values(BatteryStatus))).exclude([BatteryStatus.Unknown]),
  copies: z.coerce.number().positive(),
})
export type ZodInputTypes = z.infer<typeof packSchema>

type AddPackProps = {
  order: OrderFragment
  packTypes: PackTypeFragment[]
  selectedBoxType: BoxTypeFragment
  setAdding: (state: boolean) => void
}

export const AddPack = ({
  order,
  packTypes,
  selectedBoxType,
  setAdding,
}: AddPackProps): JSX.Element => {
  const { t } = useTranslation()
  const [addPack] = useAddPackMutation()
  const defaultValues: Required<DefaultValues<ZodInputTypes>> = {
    packTypeId: "",
    netWeight: 0,
    batteryStatus: BatteryStatus.Unknown,
    copies: 1,
  }
  const methods = useForm<ZodInputTypes>({
    resolver: zodResolver(requiredSchema),
    mode: "onBlur",
    defaultValues,
  })
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors, isValid },
  } = methods

  const watchPackTypeId = watch("packTypeId")
  const watchPackWeight = watch("netWeight")
  const grossWeight = selectedBoxType.netWeight + watchPackWeight

  useEffect(() => {
    if (watchPackTypeId) {
      setValue("netWeight", packTypes.find((p) => p.id === watchPackTypeId)?.defaultNetWeight ?? 0)
    }
  }, [packTypes, setValue, watchPackTypeId])

  const onSubmit: SubmitHandler<ZodInputTypes> = (data: ZodInputTypes) => {
    addPack({
      variables: {
        input: {
          pickupOrderId: order.id,
          etag: order.etag,
          boxType: {
            internalID: selectedBoxType.id,
            displayName: selectedBoxType.displayName,
          },
          packType: {
            internalID: data.packTypeId,
            displayName: packTypes.find((p) => p.id === data.packTypeId)?.displayName || "",
          },
          netWeight: data.netWeight,
          batteryStatus: data.batteryStatus,
          count: data.copies,
          fileIDs: [],
        },
      },
    }).then(() => {
      setAdding(false)
    })
  }

  return (
    <FormProvider {...methods}>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <CardContent>
          <Stack gap={4}>
            <Alert severity="info">{t("components.AddPack.packLimitDescription")}</Alert>
            <AutoCompleteInput
              control={control}
              fullWidth
              name="packTypeId"
              label={t("components.AddPack.selectPackType.label")}
              // disablePortal makes it so the autocomplete dropdown appears within the react part of the DOM. Important to ensure tests work correctly
              disablePortal={true}
              endAdornment={
                <InputAdornment position="start">
                  <IconCarBattery />
                </InputAdornment>
              }
              options={packTypes.map((p) => ({
                value: p.id,
                label: readableEnum(p.displayName),
              }))}
            />
            {watchPackTypeId !== undefined && (
              <>
                <Grid container spacing={2}>
                  <Grid sm={12} md={6}>
                    <NumberInput
                      label={t("components.basics.netWeight")}
                      fullWidth
                      control={control}
                      name="netWeight"
                      data-testid="net-weight-input"
                      InputProps={{
                        endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                      }}
                    />
                  </Grid>
                  <Grid sm={12} md={6}>
                    <TextField
                      type="number"
                      disabled
                      label={t("components.basics.grossWeight")}
                      fullWidth
                      value={grossWeight}
                      InputProps={{
                        endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                      }}
                    />
                  </Grid>
                </Grid>

                <Controller
                  control={control}
                  name="batteryStatus"
                  render={({ field, fieldState: { error } }) => (
                    <>
                      <Stack spacing={2} direction="row" alignItems="center">
                        <Typography fontSize={16} sx={{ mr: 2 }}>
                          {t("components.AddPack.batteryStatus.title")}
                        </Typography>
                        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                        <RadioGroup {...field} row defaultValue={undefined}>
                          <BatteryStatusLabel
                            value={BatteryStatus.Green}
                            control={<Radio />}
                            label={t("components.basics.batteryStatus.green")}
                            data-testid="battery-status-green"
                          />
                          <BatteryStatusLabel
                            value={BatteryStatus.Yellow}
                            control={<Radio />}
                            label={t("components.basics.batteryStatus.yellow")}
                            data-testid="battery-status-yellow"
                          />
                          <BatteryStatusLabel
                            value={BatteryStatus.Red}
                            control={<Radio />}
                            label={t("components.basics.batteryStatus.red")}
                            data-testid="battery-status-red"
                          />
                        </RadioGroup>
                      </Stack>
                      {error && (
                        <Box>
                          <Alert severity="error">
                            {t("components.AddPack.batteryStatus.error")}
                          </Alert>
                        </Box>
                      )}
                    </>
                  )}
                />

                <Box sx={{ boxRadius: "4px", padding: "16px" }} bgcolor="background.default">
                  <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography fontSize={16} component="h2" sx={{ margin: 2 }}>
                      {t("components.AddPack.copies.title")}
                    </Typography>
                    <NumberSelect
                      control={control}
                      setValue={setValue}
                      name="copies"
                      minNumber={1}
                    />
                  </Stack>
                  {errors.copies && <Alert severity="error">{errors.copies.message}</Alert>}
                </Box>
              </>
            )}
          </Stack>
        </CardContent>
        <CardActions>
          <CancelButton onClick={() => setAdding(false)} />
          <Button
            type="submit"
            color="secondary"
            data-testid="add-box-submit"
            disabled={!isValid}
            startIcon={<IconPlus />}
          >
            {t("components.AddBox.submit")}
          </Button>
        </CardActions>
      </form>
    </FormProvider>
  )
}
