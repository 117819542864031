import {
  OrderFragment,
  useAddLocationAndDateMutation,
  usePickupOrderLocationsQuery,
} from "@gen/graphql"
import { zodResolver } from "@hookform/resolvers/zod"
import { InputAdornment } from "@mui/material"
import { Alert, Card, CardContent, Stack, Typography } from "@northvolt/ui"
import {
  AutoCompleteInput,
  CancelButton,
  CardActions,
  Date,
  IconLocationDot,
  NextButton,
} from "@shared"
import { useNavigate } from "@tanstack/react-router"
import dayjs, { type Dayjs } from "dayjs"
import { JSX } from "react"
import { DefaultValues, SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { z } from "zod"

const formSchema = z.object({
  location: z.string().uuid(),
  date: z.instanceof(dayjs as unknown as typeof Dayjs),
})

type InputTypes = z.infer<typeof formSchema>

type LocationAndDateProps = {
  order: OrderFragment
  stepId: string
}

export const LocationAndDate = ({ order, stepId }: LocationAndDateProps): JSX.Element => {
  const { data, error } = usePickupOrderLocationsQuery({ variables: {} })
  const { t } = useTranslation()
  const navigate = useNavigate()
  const defaultValues: Required<DefaultValues<InputTypes>> = {
    location: "",
    date: dayjs(),
  }

  const [addLocationAndDate] = useAddLocationAndDateMutation()

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<InputTypes>({
    resolver: zodResolver(formSchema),
    defaultValues,
  })

  const onSubmit: SubmitHandler<InputTypes> = (submitData) => {
    addLocationAndDate({
      variables: {
        input: {
          pickupOrderId: order.id,
          etag: order.etag,
          locationId: submitData.location,
          date: submitData.date.format("YYYY-MM-DD"),
        },
      },
    }).then((res) => {
      if (!res.data?.addLocationAndDate?.id) {
        throw Error("Coudn't create an order")
      }

      navigate({
        to: "/pickup-orders/$orderId/$stepId",
        params: { orderId: res.data.addLocationAndDate.id, stepId: `${parseInt(stepId) + 1}` },
      })
    })
  }

  if (error) throw new Error("Error loading pickup order locations")

  if (!data) return <div>Loading...</div>

  return (
    <Card>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <CardContent>
          <Stack spacing={1}>
            <Typography fontSize={16} component="h2" sx={{ mb: 2 }}>
              {t("components.LocationAndDate.title")}
            </Typography>

            <AutoCompleteInput
              endAdornment={
                <InputAdornment position="end">
                  <IconLocationDot />
                </InputAdornment>
              }
              name="location"
              label={t("components.LocationAndDate.selectLocation.label")}
              control={control}
              options={data.locations.map<{ label: string; value: string }>((location) => ({
                label: location.displayName,
                value: location.id,
              }))}
            />

            <Date control={control} name="date" />

            {errors.location && (
              <Alert sx={{ bgcolor: "inherit", alignSelf: "center" }} severity="error">
                {errors.location.message}
              </Alert>
            )}
            {errors.date && (
              <Alert sx={{ bgcolor: "inherit", alignSelf: "center" }} severity="error">
                {errors.date.message}
              </Alert>
            )}
          </Stack>
        </CardContent>
        <CardActions>
          <CancelButton onClick={() => navigate({ to: "/pickup-orders" })} />
          <NextButton isValid={isValid} />
        </CardActions>
      </form>
    </Card>
  )
}
