import { Overview } from "@components/Overview/Overview"
import { Card, CardContent, Typography } from "@northvolt/ui"
import { useAppBarPortal } from "@shared"
import { Outlet, createFileRoute } from "@tanstack/react-router"
import { JSX } from "react"
import { useTranslation } from "react-i18next"
import { usePickupOrdersQuery } from "../gen/graphql"

const PickupOrderListComponent = (): JSX.Element => {
  const { t } = useTranslation()
  const Portal = useAppBarPortal()
  const { data, loading, error } = usePickupOrdersQuery({
    fetchPolicy: "network-only",
  })

  if (loading) {
    return <div>Loading...</div>
  }

  if (error) {
    return (
      <Card>
        <CardContent>
          {error.graphQLErrors.map((err) => err.message)} {error.message}
        </CardContent>
      </Card>
    )
  }

  return (
    <div>
      <Portal>
        <Typography variant="headlineMedium">{t("components.AppBar.title.overview")}</Typography>
      </Portal>
      <Overview orders={data?.pickupOrders ?? []} />
      <Outlet />
    </div>
  )
}

export const Route = createFileRoute("/overview")({
  component: () => <PickupOrderListComponent />,
})
