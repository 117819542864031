import {
  BatteryStatus,
  BoxFragment,
  BoxTypeFragment,
  OrderFragment,
  OrderStatus,
  PackFragment,
  PackTypeFragment,
  useUpdatePackMutation,
} from "@gen/graphql"
import { zodResolver } from "@hookform/resolvers/zod"
import { zodEnum } from "@lib/zod"
import { InputAdornment } from "@mui/material"
import {
  Button,
  Card,
  CardContent,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  useSnackbar,
} from "@northvolt/ui"
import { FormSelect } from "@northvolt/ui/Form"
import { BatteryStatusLabel, CardActions, IconBox, NumberInput } from "@shared"
import { useNavigate } from "@tanstack/react-router"
import { JSX, useEffect } from "react"
import { Controller, DefaultValues, SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { z } from "zod"

type AddPackageProps = {
  stepId: string
  order: OrderFragment
  box: BoxFragment
  boxTypes: BoxTypeFragment[]
  packTypes: PackTypeFragment[]
}

export const EditPack = ({
  stepId,
  order,
  box,
  boxTypes,
  packTypes,
}: AddPackageProps): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const updateSnackbar = useSnackbar()
  const [updatePackMutation, { error }] = useUpdatePackMutation()

  useEffect(() => {
    if (error?.message) {
      updateSnackbar({
        message: error.message,
        severity: "error",
      })
    }
  }, [error, updateSnackbar])

  const formSchema = z.object({
    // TODO this is two alternative approaches to validating a list of values.
    // We need to look into what is the best approach to this
    // packTypeId: z.enum(zodEnum([...packTypes.map<string>((p) => p.id)])),
    boxTypeId: z.string().refine((id) => boxTypes.some((p) => p.id === id)),
    packTypeId: z.string().refine((id) => packTypes.some((p) => p.id === id)),
    netWeight: z.number().int().min(1),
    batteryStatus: z.enum(zodEnum(Object.values(BatteryStatus))),
  })

  // TODO this is a really nasty conversion, we should use __typename for this
  let pack: PackFragment
  if (box.items.length > 0 && box.items[0].__typename === "Pack") {
    pack = box.items[0] as PackFragment
  } else {
    throw new Error("Invalid pack type")
  }

  type InputTypes = z.infer<typeof formSchema>
  const defaultValues: Required<DefaultValues<InputTypes>> = {
    boxTypeId: box.boxType.id,
    packTypeId: pack.packType.id,
    netWeight: pack.netWeight,
    batteryStatus: pack.batteryStatus,
  }

  const { handleSubmit, control, reset, watch, setValue } = useForm<InputTypes>({
    resolver: zodResolver(formSchema),
    defaultValues,
  })

  const watchPackTypeId = watch("packTypeId")
  const watchPackWeight = watch("netWeight")
  const watchboxTypeId = watch("boxTypeId")

  const packageWeight = boxTypes.find((p) => p.id === watchboxTypeId)?.netWeight ?? 0
  const grossWeight = packageWeight + watchPackWeight

  // TODO: the order of these effects actually matter atm, that's really bad.
  // We should look into how we can make this more predictable
  useEffect(() => {
    if (watchPackTypeId) {
      setValue("netWeight", packTypes.find((p) => p.id === watchPackTypeId)?.defaultNetWeight ?? 0)
    }
  }, [packTypes, setValue, watchPackTypeId])

  useEffect(() => {
    if (pack) {
      reset({
        boxTypeId: box.boxType.id,
        packTypeId: pack.packType.id,
        netWeight: pack.netWeight,
        batteryStatus: pack.batteryStatus,
      })
    }
  }, [box.boxType.id, pack, reset])

  const boxType = boxTypes.find((b) => b.id === watchboxTypeId)
  const packType = packTypes.find((p) => p.id === watchPackTypeId)

  const onSubmit: SubmitHandler<InputTypes> = (data: InputTypes) => {
    updatePackMutation({
      variables: {
        input: {
          pickupOrderId: order.id,
          etag: order.etag,
          boxId: box?.id,
          updates: {
            boxType: {
              internalID: boxType?.id,
              displayName: boxType?.displayName ?? "",
            },
            packType: {
              internalID: packType?.id,
              displayName: packType?.displayName ?? "",
            },
            netWeight: data.netWeight,
            batteryStatus: data.batteryStatus ?? BatteryStatus.Green,
          },
        },
      },
    }).then(() => {
      navigate({
        to: "/pickup-orders/$orderId/$stepId",
        params: {
          orderId: order.id,
          stepId: stepId,
        },
      })
    })
  }

  return (
    <Card>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <CardContent>
          <Stack spacing={1}>
            <CardActions>
              <Typography variant="headlineSmall">
                <IconBox /> {box.boxType.displayName}
              </Typography>
              {/* @TODO: Disable if not fully valid */}
              <Stack direction="row" gap={2}>
                <Button
                  variant="text"
                  onClick={() => {
                    navigate({
                      to: "/pickup-orders/$orderId/$stepId",
                      params: {
                        orderId: order.id,
                        stepId: stepId,
                      },
                    })
                  }}
                >
                  {t("components.basics.cancel")}
                </Button>
                <Button
                  type="submit"
                  data-testid={"edit-pack-submit-button"}
                  disabled={
                    !(order.status === OrderStatus.Draft || order.status === OrderStatus.Pending)
                  }
                >
                  {t("components.basics.save")}
                </Button>
              </Stack>
            </CardActions>

            <FormSelect
              control={control}
              fullWidth
              name="boxTypeId"
              label={t("components.basics.selectBoxType.label")}
              options={boxTypes.map((p) => ({
                value: p.id,
                label: p.displayName,
              }))}
            />
          </Stack>
          <Stack marginTop={4} gap={4}>
            <FormSelect
              control={control}
              fullWidth
              name="packTypeId"
              label={t("components.AddPack.selectPackType.label")}
              options={packTypes.map((p) => ({
                value: p.id,
                label: p.displayName,
              }))}
            />
            <Grid container spacing={2}>
              <Grid xs={12} md={6}>
                <NumberInput
                  label={t("components.basics.netWeight")}
                  fullWidth
                  control={control}
                  name="netWeight"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                  }}
                />
              </Grid>
              <Grid xs={12} md={6}>
                <TextField
                  label={t("components.basics.grossWeight")}
                  disabled
                  fullWidth
                  value={grossWeight}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">kg</InputAdornment>,
                  }}
                />
              </Grid>
            </Grid>

            <Stack spacing={2} direction="row" alignItems="center">
              <Typography fontSize={16} sx={{ mr: 2 }}>
                {t("components.AddPack.batteryStatus.title")}
              </Typography>
              <Controller
                rules={{ required: true }}
                control={control}
                name="batteryStatus"
                render={({ field }) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <RadioGroup {...field} row>
                    <BatteryStatusLabel
                      value={BatteryStatus.Green}
                      control={<Radio />}
                      label={t("components.basics.batteryStatus.green")}
                    />
                    <BatteryStatusLabel
                      value={BatteryStatus.Yellow}
                      control={<Radio />}
                      label={t("components.basics.batteryStatus.yellow")}
                    />
                    <BatteryStatusLabel
                      value={BatteryStatus.Red}
                      control={<Radio />}
                      label={t("components.basics.batteryStatus.red")}
                    />
                  </RadioGroup>
                )}
              />
            </Stack>
          </Stack>
        </CardContent>
      </form>
    </Card>
  )
}
