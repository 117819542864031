import { Button } from "@northvolt/ui"
import { IconArrowLeft, IconArrowRight } from "../../index"
import { JSX } from "react"
import { useTranslation } from "react-i18next"

type BackButtonProps = {
  onClick: () => void
}

export const CancelButton = ({ onClick }: BackButtonProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Button variant="text" color="secondary" startIcon={<IconArrowLeft />} onClick={onClick}>
      {t("components.basics.cancel")}
    </Button>
  )
}

type NextButtonProps = {
  isValid: boolean
}

export const NextButton = ({ isValid }: NextButtonProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Button type="submit" color="secondary" disabled={!isValid} endIcon={<IconArrowRight />}>
      {t("components.basics.next")}
    </Button>
  )
}
