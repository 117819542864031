import { EditBoxSmallVolumeStep } from "@components/PickupOrder/Steps/EditBoxSmallVolumeStep/EditBoxSmallVolumeStep"
import { EditBoxStep } from "@components/PickupOrder/Steps/EditBoxStep/EditBoxStep"
import { PickupOrderFlow, PickupOrderQuery } from "@gen/graphql"
import { createFileRoute } from "@tanstack/react-router"
import { JSX, useContext } from "react"
import { OrderContext } from "./pickup-orders.$orderId.$stepId"

const Error = (): JSX.Element => {
  return <div>Something went wrong</div>
}

const Base = (): JSX.Element => {
  const { order, tenant } = useContext(OrderContext)
  const { stepId, groupId, packageId } = Route.useParams()

  const box = order?.pickupOrder.boxes.find((p) => p.id === packageId)

  if (!order) return <div>Loading...</div>

  if (!box) return <div>Package not found</div>

  if (!tenant?.pickupOrderFlow) return <div>Missing pickup order flow</div>

  return stepToComponent(stepId, groupId, packageId, order, tenant?.pickupOrderFlow)
}

const stepToComponent = (
  stepId: string,
  groupId: string,
  boxId: string,
  order: PickupOrderQuery,
  steps: PickupOrderFlow[],
): JSX.Element => {
  switch (steps[parseInt(stepId)]) {
    case PickupOrderFlow.AddPackageSmallVolume:
      return (
        <EditBoxSmallVolumeStep
          stepId={stepId}
          order={order.pickupOrder}
          groupId={groupId}
          selectedId={boxId}
        />
      )
    case PickupOrderFlow.AddPackage:
      return (
        <EditBoxStep
          stepId={stepId}
          order={order.pickupOrder}
          groupId={groupId}
          selectedId={boxId}
          packTypes={order.packTypes}
          boxTypes={order.boxTypes}
          moduleTypes={order.moduleTypes}
        />
      )

    default:
      return <div>Missing pickup order flow</div>
  }
}

export const Route = createFileRoute(
  "/pickup-orders/$orderId/$stepId/groups/$groupId/packages/$packageId/edit",
)({
  loader: () => {
    // TODO Consider adding usePickupOrderLocationsQuery to the loader instead of in the component
  },
  component: Base,
  errorComponent: Error,
})
