import { AddAnotherPackage } from "@components/PickupOrder/Components/AddAnotherPackage/AddAnotherPackage"
import { AddBox } from "@components/PickupOrder/Components/AddBox/AddBox"
import { OrderSummary } from "@components/PickupOrder/Components/OrderSummary/OrderSummary"
import { usePickupOrderQuery, useSubmitOrderMutation } from "@gen/graphql"
import { Grid } from "@northvolt/ui"
import { useNavigate } from "@tanstack/react-router"
import { JSX, useState } from "react"

type AddBoxStepProps = {
  orderId: string
  stepId: string
}

export const AddBoxStep = ({ orderId, stepId }: AddBoxStepProps): JSX.Element => {
  const { data, error } = usePickupOrderQuery({
    variables: {
      id: orderId,
    },
  })
  const [submitOrder] = useSubmitOrderMutation()
  const navigate = useNavigate()
  const [adding, setAdding] = useState(false)

  if (error) throw new Error("Error loading pickup order locations")

  if (!data) return <div>Loading...</div>
  const submit = (): void => {
    submitOrder({
      variables: {
        input: {
          pickupOrderId: data.pickupOrder.id,
          etag: data.pickupOrder.etag,
        },
      },
    }).then((result) => {
      navigate({
        to: "/pickup-orders/$orderId/$stepId",
        search: {
          submittedOrderId: result.data?.submitPickupOrder.id,
        },
        params: {
          orderId,
          stepId: stepId,
        },
      })
    })
  }
  return (
    <Grid container spacing={2}>
      <Grid xs={12} md={6}>
        {!adding && <AddAnotherPackage orderId={data.pickupOrder.id} setAdding={setAdding} />}
        {adding && (
          <AddBox
            order={data.pickupOrder}
            boxTypes={data.boxTypes}
            moduleTypes={data.moduleTypes}
            packTypes={data.packTypes}
            setAdding={setAdding}
          />
        )}
      </Grid>
      <Grid xs={12} md={6}>
        <OrderSummary order={data.pickupOrder} submit={submit} stepId={stepId} />
      </Grid>
    </Grid>
  )
}
