/* eslint-disable react/jsx-props-no-spreading */
import { Autocomplete, AutocompleteProps, Box, TextField, Typography } from "@northvolt/ui"
import { JSX, useMemo } from "react"
import { Control, FieldPath, FieldValues, useController } from "react-hook-form"

type Option = {
  label: string
  value: string
}

export const AutoCompleteInput = <T extends FieldValues, U extends FieldPath<T>>({
  control,
  name,
  options,
  label,
  endAdornment,
  ...props
}: {
  control: Control<T>
  name: U
  options: readonly Option[]
  label: string
  endAdornment?: JSX.Element
} & Omit<AutocompleteProps<Option, boolean | undefined>, "renderInput">): JSX.Element => {
  const {
    field: { onChange, value: defaultValue, ...field },
    fieldState: { error },
  } = useController({ control, name })

  const selectedOptions: Option | Option[] = useMemo(() => {
    if (Array.isArray(defaultValue)) {
      return options.filter((o) => defaultValue.includes(o.value))
    }
    return options.find((o) => o.value === defaultValue) ?? []
  }, [defaultValue, options])

  return (
    <>
      <Autocomplete<Option, boolean | undefined>
        options={options}
        {...props}
        {...field}
        clearIcon={null}
        disableClearable
        value={selectedOptions}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option
          }
          if (Array.isArray(option)) {
            return option.map((o) => o.label).join(", ")
          }
          return option.label
        }}
        onChange={(_, value): void => {
          if (typeof value === "string") {
            onChange(value)
          }
          if (Array.isArray(value)) {
            onChange(
              value.map((v) => {
                if (typeof v === "string") {
                  return v
                }
                return v.value
              }),
            )
          }
          if (!Array.isArray(value) && typeof value === "object") {
            onChange(value?.value)
          }
        }}
        renderInput={(params) => {
          const { InputProps, ...rest } = params
          if (endAdornment) {
            InputProps.endAdornment = (
              <>
                {endAdornment}
                {InputProps.endAdornment}
              </>
            )
          }
          return <TextField {...rest} InputProps={InputProps} label={label} />
        }}
      />
      {error && (
        <Box>
          <Typography color="error">{error?.message}</Typography>
        </Box>
      )}
    </>
  )
}
