import { ListOrderBoxes } from "@components/PickupOrder/Components/ListOrderBoxes/ListOrderBoxes"
import { OrderListFragment } from "@gen/graphql"
import { Theme, useMediaQuery } from "@mui/material"
import { styled } from "@mui/material/styles"
import { Box, Drawer, Grid, IconButton, Stack, Typography } from "@northvolt/ui"
import { IconPen, IconXmark } from "@shared"
import { useNavigate } from "@tanstack/react-router"
import { JSX, useEffect, useState } from "react"

type PickupOrderListProps = {
  orders: OrderListFragment[]
  selectedOrderId?: string
}

const sumOrderNetWeight = (order: OrderListFragment): number =>
  order.boxes.reduce(
    (acc, box) =>
      acc +
      box.items.reduce((a, i) => {
        if (i.__typename === "Pack") {
          return a + i.netWeight
        }
        if (i.__typename === "Module") {
          return a + i.netWeight * (i.green + i.yellow + i.red)
        }
        return a
      }, 0),
    0,
  )

const sumOrderGrossWeight = (order: OrderListFragment): number =>
  sumOrderNetWeight(order) + order.boxes.reduce((acc, box) => acc + box.boxType.netWeight, 0)

const sumOrderItems = (order: OrderListFragment): number => order.boxes.length

export const OverviewDrawer = ({ orders, selectedOrderId }: PickupOrderListProps): JSX.Element => {
  const navigate = useNavigate()
  const [selectedOrder, setOpen] = useState<OrderListFragment | undefined>()
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"))
  const toggleDrawer = (order: OrderListFragment | undefined): void => {
    setOpen(order)
    if (order) {
      navigate({
        to: "/overview/$orderId",
        params: {
          orderId: order.id,
        },
      })
    } else {
      navigate({
        to: "/overview",
      })
    }
  }

  useEffect(() => {
    if (selectedOrderId) {
      const order = orders.find((o) => o.id === selectedOrderId)
      if (order) {
        setOpen(order)
      }
    }
  }, [selectedOrderId, orders])

  return isDesktop ? (
    <Drawer
      anchor="right"
      open={selectedOrder !== undefined}
      onClose={() => toggleDrawer(undefined)}
    >
      {selectedOrder && <DrawerContent order={selectedOrder} toggleDrawer={toggleDrawer} />}
    </Drawer>
  ) : (
    <MobileDrawer
      anchor="bottom"
      open={selectedOrder !== undefined}
      onClose={() => toggleDrawer(undefined)}
    >
      {selectedOrder && <DrawerContent order={selectedOrder} toggleDrawer={toggleDrawer} />}
    </MobileDrawer>
  )
}

type DrawerContentProps = {
  order: OrderListFragment
  toggleDrawer: (order: OrderListFragment | undefined) => void
}

const DrawerContent = ({ order, toggleDrawer }: DrawerContentProps): JSX.Element => {
  const navigate = useNavigate()

  return (
    <Box paddingX={3} paddingY={4}>
      <Grid container spacing={2}>
        <Grid sm={10} md={10}>
          <Typography variant="headlineSmall">Pickup Orders</Typography>
          <Typography color="secondary">{order?.pickupDate}</Typography>
        </Grid>
        <Grid sm={2} md={2} marginTop={1}>
          <Stack direction="row" gap={1} justifyContent="flex-end">
            <IconButton
              size="small"
              onClick={() => {
                navigate({
                  to: "/pickup-orders/$orderId/$stepId",
                  params: {
                    orderId: order.id,
                    stepId: "1",
                  },
                })
              }}
            >
              <IconPen />
            </IconButton>

            <IconButton size="small" onClick={() => toggleDrawer(undefined)}>
              <IconXmark />
            </IconButton>
          </Stack>
        </Grid>
      </Grid>

      <Grid container spacing={2} marginY={2}>
        <Grid xs={12} sm={6}>
          <Typography>Total&nbsp;packages</Typography>
          <Typography variant="headlineSmall">{sumOrderItems(order)}</Typography>
        </Grid>
        <Grid xs={12} sm={6}>
          <Typography>Gross weight</Typography>
          <Typography variant="headlineSmall">{sumOrderGrossWeight(order)} kg</Typography>
        </Grid>
      </Grid>
      <ListOrderBoxes order={order} editable={false} stepId={"2"} />
    </Box>
  )
}

const MobileDrawer = styled(Drawer)(() => ({
  "& .MuiDrawer-paper": {
    borderRadius: "16px 16px 0 0",
  },
}))
