import { getColorFromStatus } from "../../lib/typography"
import { styled } from "@mui/material/styles"
import { Box, Typography } from "@northvolt/ui"
import { JSX } from "react"

// These are intentionally not taken from the Graphql schema to decouple the UI from the API
type BatteryStatus = "Green" | "Yellow" | "Red" | "Unknown"

type StatusChipProps = {
  label: string
  status: BatteryStatus
  pill?: boolean
}

export const BatteryStatus = ({ label, status, pill = false }: StatusChipProps): JSX.Element => {
  const typography = (
    <TypographyBatteryStatus status={status} variant="captionSmall">
      {label}
    </TypographyBatteryStatus>
  )

  return pill ? <Pill>{typography}</Pill> : typography
}

const TypographyBatteryStatus = styled(Typography)<{ status: BatteryStatus }>(
  ({ theme, status }) => `
  position: relative;

  &::after {
    content: "";
    border-radius: 50%;
    width: 12px;
    height: 12px;
    display: inline-block;
    position: relative;
    top: 2px;
    left: 10px;
    background: ${theme.palette[getColorFromStatus(status)].main};
  }
  `,
)

const Pill = styled(Box)`
  font-size: 14px;
  border: 1px solid ${({ theme }) => theme.palette.grey[300]};
  background: ${({ theme }) =>
    theme.palette.mode === "dark" ? theme.palette.grey[200] : theme.palette.grey[800]};
  border-radius: 100px;
  padding-right: 16px;
  padding-left: 8px;
  display: inline-block;
`
