import { Card, Container, NVThemeProvider, Typography } from "@northvolt/ui"

export const MissingRole = (): JSX.Element => {
  return (
    <NVThemeProvider useSnackbar usePreferredTheme brand="revolt">
      <Container>
        <Card component="main" sx={{ p: 4, m: 10 }}>
          <Typography variant="headlineLarge" data-testId="missing-permissions">
            Missing permissions
          </Typography>
          <Typography variant="bodyMedium">
            If you believe this is an error, please contact your administrator.
          </Typography>
        </Card>
      </Container>
    </NVThemeProvider>
  )
}
