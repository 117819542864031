import { ApolloClient, ApolloLink, InMemoryCache, ApolloProvider as Provider } from "@apollo/client"
import createUploadLink from "apollo-upload-client/createUploadLink.mjs"
import React, { JSX } from "react"
import { useIdToken } from "./AuthProvider"
import { onError } from "@apollo/client/link/error"
import * as Sentry from "@sentry/browser"

type Config = {
  apiURL: string
  mode: string
}

export const ApolloProvider = ({
  children,
  config,
}: {
  children: React.JSX.Element
  config: Config
}): JSX.Element => {
  const idToken = useIdToken()

  const client = new ApolloClient({
    uri: config.apiURL,
    cache: new InMemoryCache(),
    link: ApolloLink.from([
      errorLink,
      createUploadLink({
        uri: config.apiURL,
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      }),
    ]),
    headers: {
      Authorization: `Bearer ${idToken}`,
    },
    // connectToDevTools: config.mode === "development",
  })

  return <Provider client={client}>{children}</Provider>
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      Sentry.captureException(
        new Error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`),
      )
    })
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
    Sentry.captureException(new Error(`[Network error]: ${networkError}`))
  }
})
