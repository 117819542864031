import { SubmittedModal } from "@components/PickupOrder/Components/SubmittedModal/SubmittedModal"
import { AddBoxSmallVolumeStep } from "@components/PickupOrder/Steps/AddBoxSmallVolumeStep/AddBoxSmallVolumeStep"
import { AddBoxStep } from "@components/PickupOrder/Steps/AddBoxStep/AddBoxStep"
import { ExampleStep } from "@components/PickupOrder/Steps/ExampleStep/ExampleStep"
import { IntroStep } from "@components/PickupOrder/Steps/IntroStep/IntroStep"
import { LocationAndDateStep } from "@components/PickupOrder/Steps/LocationAndDateStep/LocationAndDateStep"
import { OrderFragment, PickupOrderFlow, usePickupOrderQuery, useTenantQuery } from "@gen/graphql"
import { createFileRoute } from "@tanstack/react-router"
import { JSX } from "react"

const PickupOrderError = (): JSX.Element => {
  return <div>Something went wrong</div>
}

const PickupOrderBase = (): JSX.Element => {
  const { submittedOrderId } = Route.useSearch<{ submittedOrderId: string }>()
  const { orderId, stepId } = Route.useParams()
  const { data: tenant } = useTenantQuery()

  const { data, error } = usePickupOrderQuery({
    variables: {
      id: orderId,
    },
  })

  if (error) throw new Error("Error loading pickup order")

  if (!data) return <div>Loading...</div>

  return (
    <>
      {submittedOrderId && <SubmittedModal order={data?.pickupOrder} />}
      {tenant?.tenant?.pickupOrderFlow &&
        stepToComponent(data.pickupOrder, stepId, tenant?.tenant?.pickupOrderFlow)}
    </>
  )
}

const stepToComponent = (
  order: OrderFragment,
  stepId: string,
  steps: PickupOrderFlow[],
): JSX.Element => {
  switch (steps[parseInt(stepId)]) {
    case PickupOrderFlow.Intro:
      return <IntroStep />
    case PickupOrderFlow.Example:
      return <ExampleStep orderId={order.id} stepId={stepId} />
    case PickupOrderFlow.LocationAndDate:
      return <LocationAndDateStep order={order} stepId={stepId} />
    case PickupOrderFlow.AddPackage:
      return <AddBoxStep orderId={order.id} stepId={stepId} />
    case PickupOrderFlow.AddPackageSmallVolume:
      return <AddBoxSmallVolumeStep orderId={order.id} stepId={stepId} />
    default:
      return <div>Missing pickup order flow</div>
  }
}

export const Route = createFileRoute("/pickup-orders/$orderId/$stepId/")({
  component: PickupOrderBase,
  errorComponent: PickupOrderError,
  pendingComponent: () => <div>Loading...</div>,
})
