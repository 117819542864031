import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  AppBar as MuiAppBar,
  Stack,
  Theme,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material"
import { IconHamburger, IconUser } from "../../index"
import Northvolt from "../MainLayout/northvolt.svg"
import { useNavigate } from "@tanstack/react-router"
import { JSX, MouseEvent, ReactPortal, useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { createPortal, unmountComponentAtNode } from "react-dom"
import styled from "@mui/system/styled"

type AppBarProps = {
  toggleOpen: () => void
  logout?: () => void
}

export const AppBar = ({ toggleOpen, logout }: AppBarProps): JSX.Element => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = (): void => {
    setAnchorEl(null)
  }

  return (
    <StyledAppBar sx={{ padding: "0 16px" }}>
      <Toolbar disableGutters sx={{ justifyContent: "space-between", minHeight: "64px" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton
            size="small"
            color="inherit"
            aria-label="Toggle drawer"
            sx={(theme: Theme) => ({
              minHeight: 48,
              justifyContent: "initial",
              p: theme.spacing(2, 2, 2, 1.7), // to make the chevron align with the icons when flipped
              borderRadius: 0, // fixes weird border radius on chevron icons
            })}
            onClick={() => {
              toggleOpen()
            }}
          >
            <IconHamburger />
          </IconButton>
          <Stack
            sx={{ display: "flex", cursor: "pointer" }}
            onClick={() =>
              navigate({
                to: "/",
              })
            }
          >
            <Northvolt />
            <Typography>Revolt</Typography>
          </Stack>
        </Box>
        <Box id="app-bar-portal"></Box>
        <Tooltip title={t("components.AppBar.ProfileMenuTooltip")}>
          <IconButton onClick={handleClick} sx={{ p: 0 }} size="small">
            <IconUser />
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          sx={{ mt: "54px" }}
          id="menu-appbar"
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <MenuItem
            onClick={() =>
              navigate({
                to: "/profile",
              })
            }
          >
            <Typography textAlign="center">{t("components.AppBar.ProfileMenu.profile")}</Typography>
          </MenuItem>
          {logout && (
            <MenuItem onClick={() => logout()}>
              <Typography textAlign="center">{t("components.AppBar.LogoutMenu.logout")}</Typography>
            </MenuItem>
          )}
        </Menu>
      </Toolbar>
    </StyledAppBar>
  )
}

export const useAppBarPortal = () => {
  const el = document.querySelector("#app-bar-portal")

  const [portal, setPortal] = useState<{
    render: ({ children }: { children: JSX.Element }) => ReactPortal | JSX.Element
    remove: () => boolean
  }>({
    render: () => <></>,
    remove: () => false,
  })

  const create = useCallback((el: Element) => {
    //render a portal at the given DOM node:
    const Portal = ({ children }: { children: JSX.Element }) => {
      if (el === null) return <></>
      return createPortal(children, el)
    }
    //delete the portal from memory:
    const remove = () => {
      if (el === null) return false
      return unmountComponentAtNode(el)
    }
    return { render: Portal, remove }
  }, [])

  useEffect(() => {
    if (el === null) return
    //if there is an existing portal, remove the new instance.
    //is prevents memory leaks
    if (el) portal.remove()
    //otherwise, create a new portal and render it
    const newPortal = create(el)
    setPortal(newPortal)
    //when the user exits the page, delete the portal from memory.
    return () => {
      if (newPortal) {
        newPortal.remove()
      }
    }
  }, [el])

  return portal.render
}

const StyledAppBar = styled(MuiAppBar)(
  ({ theme }) => `
  background: ${theme.palette.background.paper};
  color: ${theme.palette.mode === "dark" ? theme.palette.grey[800] : theme.palette.grey[200]};
`,
)
