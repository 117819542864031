import { LocationAndDate } from "@components/PickupOrder/Components/LocationAndDate/LocationAndDate"
import { OrderSummary } from "@components/PickupOrder/Components/OrderSummary/OrderSummary"
import { OrderFragment } from "@gen/graphql"
import { Grid } from "@northvolt/ui"
import { useNavigate } from "@tanstack/react-router"
import { JSX, useEffect } from "react"

type LocationAndDateStepProps = {
  order: OrderFragment
  stepId: string
}

export const LocationAndDateStep = ({ order, stepId }: LocationAndDateStepProps): JSX.Element => {
  const navigate = useNavigate()
  useEffect(() => {
    // Check criteria to consider this step complete and navigate to the next step
    if (order.pickupLocation?.id) {
      navigate({
        to: "/pickup-orders/$orderId/$stepId",
        params: { orderId: order.id, stepId: `${parseInt(stepId) + 1}` },
      })
    }
  }, [order, navigate, stepId])

  return (
    <Grid container spacing={2}>
      <Grid xs={12} md={6}>
        {!order.pickupLocation?.id && <LocationAndDate stepId={stepId} order={order} />}
      </Grid>
      <Grid xs={12} md={6}>
        <OrderSummary order={null} stepId={stepId} />
      </Grid>
    </Grid>
  )
}
