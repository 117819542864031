import { translationsDEDE, translationsENGB } from "@shared"
import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import { z } from "zod"
// import { zodI18nMap } from "zod-i18n-map"
import deDe from "../../locales/de-DE/common.json"
import enGb from "../../locales/en-GB/common.json"

export const defaultNS = "common"

export const resources = {
  "en-GB": {
    common: enGb,
    components: translationsENGB,
  },
  "de-DE": {
    common: deDe,
    components: translationsDEDE,
  },
} as const

i18n.use(initReactI18next).init({
  lng: "en-GB",
  debug: false,
  defaultNS,
  resources,
  interpolation: {
    escapeValue: false,
  },
})

// TODO figure out this
// z.setErrorMap(zodI18nMap)
// z.setErrorMap(makeZodI18nMap({ ns:" }))

export { i18n, z }

// eslint-disable-next-line import/no-default-export
// export default i18n
