import { SubmittedModal } from "@components/PickupOrder/Components/SubmittedModal/SubmittedModal"
import {
  OrderStatus,
  PickupOrderQuery,
  TenantFragment,
  usePickupOrderQuery,
  useTenantQuery,
} from "@gen/graphql"
import { Typography } from "@mui/material"
import { useAppBarPortal } from "@shared"
import { Outlet, createFileRoute } from "@tanstack/react-router"
import { JSX, createContext } from "react"
import { useTranslation } from "react-i18next"

export const OrderContext = createContext<{
  order: PickupOrderQuery | undefined
  tenant: TenantFragment | undefined | null
}>({
  order: undefined,
  tenant: undefined,
})

const PickupOrderError = (): JSX.Element => {
  return <div>Something went wrong</div>
}

const PickupOrderBase = (): JSX.Element => {
  const { submittedOrderId } = Route.useSearch<{ submittedOrderId: string }>()
  const { orderId } = Route.useParams()
  const { t } = useTranslation()
  const AppBarPortal = useAppBarPortal()
  const { data: tenant } = useTenantQuery()
  const { data: order, error } = usePickupOrderQuery({
    variables: {
      id: orderId,
    },
  })

  // const value = useMemo(() => ({ order: data, setOrder }), [data, setOrder])

  if (error) throw new Error("Error loading pickup order")

  if (!order) return <div>Loading...</div>

  const title =
    order?.pickupOrder.status === OrderStatus.Draft
      ? t("components.AppBar.title.pickupOrder")
      : t("components.AppBar.title.updatePickupOrder")

  return (
    <OrderContext.Provider value={{ order: order, tenant: tenant?.tenant }}>
      <AppBarPortal>
        <Typography variant="headlineMedium">{title}</Typography>
      </AppBarPortal>
      {submittedOrderId && <SubmittedModal order={order?.pickupOrder} />}
      <Outlet />
    </OrderContext.Provider>
  )
}

export const Route = createFileRoute("/pickup-orders/$orderId/$stepId")({
  component: PickupOrderBase,
  errorComponent: PickupOrderError,
  pendingComponent: () => <div>Loading...</div>,
})
