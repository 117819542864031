import { PackSummary } from "@components/PickupOrder/Components/PackSummary/PackSummary"
import { BoxFragment, OrderFragment, useDeleteGroupMutation } from "@gen/graphql"
import { styled } from "@mui/material/styles"
import { Box, Card, IconButton, Typography } from "@northvolt/ui"
import { IconArrowRight, IconBoxesStacked, IconTrashCan } from "@shared"
import { useNavigate } from "@tanstack/react-router"
import { JSX } from "react"
import { useTranslation } from "react-i18next"

type PackProps = {
  stepId: string
  order: OrderFragment
  boxes: BoxFragment[]
  editable: boolean
}

export const PackGroupSummary = ({ stepId, order, boxes, editable }: PackProps): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [deleteGroup] = useDeleteGroupMutation()

  if (boxes.length === 1)
    return <PackSummary order={order} orderBox={boxes[0]} editable={editable} />

  return (
    <PackCard variant="outlined" sx={{ padding: 1 }}>
      <Box sx={{ p: 1 }}>
        <IconBoxesStacked />
      </Box>
      <Box flexGrow={1}>
        <Typography>{t("components.PackGroupSummary.title")}</Typography>
        <Typography>
          {t("components.PackGroupSummary.packages", { packs: boxes.length })}
        </Typography>
      </Box>
      {editable && (
        <Box sx={{ p: 1 }}>
          <IconButton
            size="small"
            onClick={() => {
              navigate({
                to: "/pickup-orders/$orderId/$stepId/groups/$groupId/packages/$packageId/edit",
                params: {
                  stepId: stepId,
                  orderId: order.id,
                  groupId: boxes[0].group as string,
                  packageId: boxes[0].id,
                },
              })
            }}
          >
            <IconArrowRight />
          </IconButton>
        </Box>
      )}
      {editable && (
        <Box>
          <IconButton
            size="small"
            onClick={() => {
              deleteGroup({
                variables: {
                  input: {
                    etag: order.etag,
                    pickupOrderId: order.id,
                    groupId: boxes[0].group,
                  },
                },
              })
            }}
          >
            <IconTrashCan />
          </IconButton>
        </Box>
      )}
    </PackCard>
  )
}

const PackCard = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
