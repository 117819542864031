import { OverviewDrawer } from "@components/Overview/OverviewDrawer"
import { Card, CardContent } from "@northvolt/ui"
import { createFileRoute } from "@tanstack/react-router"
import { JSX } from "react"
import { usePickupOrdersQuery } from "../gen/graphql"

const PickupOrderListComponent = (): JSX.Element => {
  const { orderId } = Route.useParams()

  const { data, loading, error } = usePickupOrdersQuery({
    fetchPolicy: "network-only",
  })

  if (loading) {
    return <span />
  }

  if (error) {
    return (
      <Card>
        <CardContent>
          {error.graphQLErrors.map((err) => err.message)} {error.message}
        </CardContent>
      </Card>
    )
  }

  return <OverviewDrawer orders={data?.pickupOrders ?? []} selectedOrderId={orderId} />
}

export const Route = createFileRoute("/overview/$orderId")({
  component: () => <PickupOrderListComponent />,
})
