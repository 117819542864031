import { OrderListFragment } from "@gen/graphql"
import { Typography } from "@northvolt/ui"
import { JSX } from "react"
import { PickupOrderList } from "./PickupOrderList"

type OverviewProps = {
  orders: OrderListFragment[]
}

export const Overview = ({ orders }: OverviewProps): JSX.Element => {
  return (
    <>
      <Typography variant="headlineMedium" marginBottom={4}>
        Pickup Orders
      </Typography>

      <PickupOrderList orders={orders} />
    </>
  )
}
