import { BoxFragment, OrderFragment, PackFragment, useDeleteBoxMutation } from "@gen/graphql"
import { styled } from "@mui/material/styles"
import { Box, Card, IconButton, Typography } from "@northvolt/ui"
import { BatteryStatus, IconBox, IconPen, IconTrashCan } from "@shared"
import { useNavigate } from "@tanstack/react-router"
import { JSX } from "react"
import { useTranslation } from "react-i18next"

type PackProps = {
  order: OrderFragment
  orderBox: BoxFragment
  selectedId?: string
  editable: boolean
}

export const PackSummary = ({ order, orderBox, selectedId, editable }: PackProps): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [deleteBox] = useDeleteBoxMutation()
  const pack = orderBox.items[0] as PackFragment

  return (
    <PackCard selected={selectedId === orderBox.id} variant="outlined" sx={{ padding: 1 }}>
      <Box sx={{ p: 1 }}>
        <IconBox />
      </Box>
      <Box flexGrow={1}>
        <Typography>{orderBox.boxType.displayName}</Typography>
        <Typography>{t("components.basics.pack")}</Typography>
        <BatteryStatus pill status={pack.batteryStatus} label={pack.packType.displayName} />
      </Box>
      {editable && (
        <Box sx={{ p: 1 }}>
          <IconButton
            size="small"
            onClick={() => {
              navigate({
                to: "/pickup-orders/$orderId/$stepId/groups/$groupId/packages/$packageId/edit",
                params: {
                  orderId: order.id,
                  stepId: "2",
                  groupId: orderBox.group,
                  packageId: orderBox.id,
                },
              })
            }}
          >
            <IconPen />
          </IconButton>
        </Box>
      )}
      {editable && (
        <Box>
          <IconButton
            size="small"
            onClick={() =>
              deleteBox({
                variables: {
                  input: {
                    etag: order.etag,
                    pickupOrderId: order.id,
                    boxId: orderBox.id,
                  },
                },
              })
            }
          >
            <IconTrashCan />
          </IconButton>
        </Box>
      )}
    </PackCard>
  )
}

const PackCard = styled(Card)<{ selected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme, selected }) =>
    selected ? theme.palette.background.default : theme.palette.background.paper};
`
