import { OrderSummary } from "@components/PickupOrder/Components/OrderSummary/OrderSummary"
import { Button, Grid } from "@northvolt/ui"
import { useNavigate } from "@tanstack/react-router"
import { JSX } from "react"

type ExampleStepStepProps = {
  orderId: string
  stepId: string
}

export const ExampleStep = ({ orderId, stepId }: ExampleStepStepProps): JSX.Element => {
  const navigate = useNavigate()

  return (
    <Grid container spacing={2}>
      <Grid xs={12} md={6}>
        <Button
          onClick={() => {
            navigate({
              to: "/pickup-orders/$orderId/$stepId",
              params: {
                orderId,
                stepId: `${stepId + 1}`,
              },
            })
          }}
        >
          Example button
        </Button>
      </Grid>
      <Grid sm={12} md={6}>
        <OrderSummary order={null} stepId={stepId} />
      </Grid>
    </Grid>
  )
}
