import { useMeQuery } from "@gen/graphql"
import { i18n } from "@lib/i18n"
import { Card, CardContent } from "@northvolt/ui"
import { Profile } from "@shared"
import { createFileRoute } from "@tanstack/react-router"
import { JSX } from "react"

const ProfilePage = (): JSX.Element => {
  const { data, error, loading } = useMeQuery()

  if (loading) {
    return <div>Loading...</div>
  }

  if (error) {
    return (
      <Card>
        <CardContent>
          {error.graphQLErrors.map((err) => err.message)} {error.message}
        </CardContent>
      </Card>
    )
  }

  if (!data?.me) {
    return (
      <Card>
        <CardContent>Couldn't fetch the user information</CardContent>
      </Card>
    )
  }

  return <Profile user={data?.me} i18n={i18n} />
}

export const Route = createFileRoute("/profile")({
  component: () => <ProfilePage />,
})
