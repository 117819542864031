export const readableEnum = (enumString: string): string => {
  return enumString
    .replace("_", " ")
    .toLowerCase()
    .replace(/\b\w/g, (l) => l.toUpperCase())
}

type BatteryStatus = "Green" | "Yellow" | "Red" | "Unknown"
type BatteryStatusColor = "success" | "warning" | "error"

export const getColorFromStatus = (status: BatteryStatus): BatteryStatusColor => {
  switch (status) {
    case "Green":
      return "success"
    case "Yellow":
      return "warning"
    case "Red":
      return "error"
    case "Unknown":
      return "error"
    default:
      return "success"
  }
}
