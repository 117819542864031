/* eslint-disable react/jsx-props-no-spreading */
import { JSX } from "react"
import { Control, FieldPath, FieldValues, useController } from "react-hook-form"
import { DatePickerDayjs } from "@northvolt/ui/Dayjs"

export const Date = <T extends FieldValues, U extends FieldPath<T>>({
  control,
  name,
}: {
  control: Control<T>
  name: U
}): JSX.Element => {
  const {
    field: { ref, onChange, value, disabled },
    fieldState: { error },
  } = useController({ control, name })

  return (
    <>
      <DatePickerDayjs
        format="YYYY-MM-DD"
        ref={ref}
        value={value}
        name={name}
        disabled={disabled}
        onChange={onChange}
      />
      {error && <div>{error.message}</div>}
    </>
  )
}
