import { OrderListFragment, OrderStatus } from "@gen/graphql"
import { Chip } from "@mui/material"
import { styled } from "@mui/material/styles"
import { Theme } from "@mui/system"
import { Box, Button, Card, Typography } from "@northvolt/ui"
import { DataGrid } from "@northvolt/ui/DataGrid"
import { IconStar } from "@shared"
import { useNavigate } from "@tanstack/react-router"
import { JSX } from "react"

type PickupOrderListProps = {
  orders: OrderListFragment[]
}

const sumOrderNetWeight = (order: OrderListFragment): number =>
  order.boxes.reduce(
    (acc, box) =>
      acc +
      box.items.reduce((a, i) => {
        if (i.__typename === "Pack") {
          return a + i.netWeight
        }
        if (i.__typename === "Module") {
          return a + i.netWeight * (i.green + i.yellow + i.red)
        }
        return a
      }, 0),
    0,
  )

const sumOrderGrossWeight = (order: OrderListFragment): number =>
  sumOrderNetWeight(order) + order.boxes.reduce((acc, box) => acc + box.boxType.netWeight, 0)

const sumOrderItems = (order: OrderListFragment): number => order.boxes.length

export const PickupOrderList = ({ orders }: PickupOrderListProps): JSX.Element => {
  const navigate = useNavigate()

  const toggleDrawer = (orderId: string | undefined): void => {
    if (orderId) {
      navigate({
        to: "/overview/$orderId",
        params: {
          orderId: orderId,
        },
      })
    } else {
      navigate({
        to: "/overview",
      })
    }
  }

  return (
    <Card>
      <StyledDataGrid
        autoHeight
        getRowHeight={() => "auto"}
        columns={[
          {
            field: "licensePlate",
            headerName: "Order ID",
            width: 200,
          },
          {
            field: "status",
            headerName: "Status",
            flex: 1,
            width: 200,
            renderCell: (params) => (
              <StatusChip
                status={params.value}
                label={params.value}
                icon={<IconStar style={{ marginLeft: "8px" }} />}
              />
            ),
          },
          {
            field: "pickupDate",
            headerName: "Pickup Date",
            flex: 1,
            width: 200,
          },
          {
            field: "pickupLocation",
            headerName: "Pickup Location",
            flex: 1,
            width: 400,
          },
          {
            field: "orderContent",
            headerName: "Order Content",
            flex: 1,
            width: 200,
            renderCell: (params) => {
              return (
                <Box>
                  <Typography>{params.value} packages</Typography>
                  <Button
                    variant="text"
                    onClick={() => {
                      toggleDrawer(params.row.id)
                    }}
                  >
                    Show more
                  </Button>
                </Box>
              )
            },
          },
          { field: "netWeight", headerName: "Net Weight", flex: 1, width: 200 },
          {
            field: "grossWeight",
            headerName: "Gross Weight",
            flex: 1,
            width: 200,
          },
        ]}
        rows={orders
          .map((order) => ({
            id: order.id,
            status: order.status,
            pickupDate: order.pickupDate,
            pickupLocation: order.pickupLocation?.displayName,
            orderContent: sumOrderItems(order),
            netWeight: sumOrderNetWeight(order),
            grossWeight: sumOrderGrossWeight(order),
            licensePlate: order.licensePlate,
          }))
          .reverse()}
      />
    </Card>
  )
}

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 0,
  "& .MuiDataGrid-columnHeader": {
    background: `${theme.palette.mode === "light" ? "#f0f0f0" : "#303030"}`,
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: 700,
  },
  "& .MuiDataGrid-cell--withRenderer": {
    padding: "16px",
  },
}))

const StatusChip = styled(Chip)<{ status: OrderStatus }>`
  background: ${({ theme, status }) => statusToColors(theme, status).background};
  border: 1px solid ${({ theme, status }) => statusToColors(theme, status).border};
  color: ${({ theme, status }) => statusToColors(theme, status).color};
`

const statusToColors = (
  theme: Theme,
  status: OrderStatus,
): { background: string; border: string; color: string } => {
  switch (status) {
    case OrderStatus.Draft:
      return {
        border: theme.palette.text.main,
        color: theme.palette.text.dark,
        background: theme.palette.text.light,
      }
    case OrderStatus.Pending:
      return {
        border: theme.palette.warning.main,
        color: theme.palette.warning.dark,
        background: theme.palette.warning.light,
      }
    case OrderStatus.Approved:
      return {
        border: theme.palette.success.main,
        color: theme.palette.success.dark,
        background: theme.palette.success.light,
      }
    case OrderStatus.Arrived:
      return {
        border: theme.palette.info.main,
        color: theme.palette.info.dark,
        background: theme.palette.info.light,
      }
    default:
      return {
        border: theme.palette.text.main,
        color: theme.palette.text.dark,
        background: theme.palette.text.light,
      }
  }
}
