import { AddItemSmallVolume } from "@components/PickupOrder/Components/AddItemSmallVolume/AddItemSmallVolume"
import { AddModuleSmallVolume } from "@components/PickupOrder/Components/AddModuleSmallVolume/AddModuleSmallVolume"
import { AddPackSmallVolume } from "@components/PickupOrder/Components/AddPackSmallVolume/AddPackSmallVolume"
import { OrderSummary } from "@components/PickupOrder/Components/OrderSummary/OrderSummary"
import { usePickupOrderQuery, useSubmitOrderMutation } from "@gen/graphql"
import { Grid } from "@northvolt/ui"
import { useNavigate, useSearch } from "@tanstack/react-router"
import { JSX } from "react"

type AddBoxSmallVolumeStepProps = {
  orderId: string
  stepId: string
}

type InternalStep = "pack" | "module"

export const AddBoxSmallVolumeStep = ({
  orderId,
  stepId,
}: AddBoxSmallVolumeStepProps): JSX.Element => {
  const { data, error } = usePickupOrderQuery({
    variables: {
      id: orderId,
    },
  })
  const [submitOrder] = useSubmitOrderMutation()
  const navigate = useNavigate()
  const searchParams: { type: InternalStep | undefined } = useSearch({
    strict: false,
  })
  const setType = (type: InternalStep | undefined): void => {
    // Originally this code was just taking the search params.
    // But typescript gets sad when you don't include `to`.
    navigate({
      to: "/pickup-orders/$orderId/$stepId",
      search: {
        type: type,
      },
      params: {
        orderId,
        stepId: stepId,
      },
    })
  }

  if (error) throw new Error("Error loading pickup order")

  if (!data) return <div>Loading...</div>
  const submit = (): void => {
    submitOrder({
      variables: {
        input: {
          pickupOrderId: data.pickupOrder.id,
          etag: data.pickupOrder.etag,
        },
      },
    }).then((result) => {
      navigate({
        to: "/pickup-orders/$orderId/$stepId",
        search: {
          submittedOrderId: result.data?.submitPickupOrder.id,
        },
        params: {
          orderId,
          stepId: stepId,
        },
      })
    })
  }
  return (
    <Grid container spacing={2}>
      <Grid sm={12} md={6}>
        {!searchParams.type && (
          <AddItemSmallVolume orderId={data.pickupOrder.id} setType={setType} />
        )}
        {searchParams.type === "pack" && (
          <AddPackSmallVolume order={data.pickupOrder} setType={setType} />
        )}
        {searchParams.type === "module" && (
          <AddModuleSmallVolume order={data.pickupOrder} setType={setType} />
        )}
        {/* {adding && (
          <AddBox
            order={data.pickupOrder}
            boxTypes={data.boxTypes}
            moduleTypes={data.moduleTypes}
            packTypes={data.packTypes}
            setAdding={setAdding}
          />
        )} */}
      </Grid>
      <Grid sm={12} md={6}>
        <OrderSummary
          stepId={stepId}
          order={data.pickupOrder}
          submit={searchParams.type ? undefined : submit}
        />
      </Grid>
    </Grid>
  )
}
