import { BoxFragment, ModuleFragment, OrderFragment, useDeleteGroupMutation } from "@gen/graphql"
import { styled } from "@mui/material/styles"
import { Box, Card, IconButton, Typography } from "@northvolt/ui"
import { IconArrowRight, IconBoxesStacked, IconTrashCan } from "@shared"
import { useNavigate } from "@tanstack/react-router"
import { JSX } from "react"
import { useTranslation } from "react-i18next"
import { ModuleSummary } from "../ModuleSummary/ModuleSummary"

type PackProps = {
  stepId: string
  order: OrderFragment
  boxes: BoxFragment[]
  editable: boolean
}

export const ModuleGroupSummary = ({ stepId, order, boxes, editable }: PackProps): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [deleteGroup] = useDeleteGroupMutation()

  if (boxes.length === 1) {
    return <ModuleSummary order={order} orderBox={boxes[0]} editable={editable} />
  }

  const modules: ModuleFragment[] = []
  boxes.forEach((p) => {
    modules.push(...p.items.filter((m): m is ModuleFragment => m.__typename === "Module"))
  })
  const modulesCount = modules.reduce((acc, cur) => acc + cur.green + cur.yellow + cur.red, 0)

  return (
    <PackCard variant="outlined" sx={{ padding: 1 }}>
      <Box sx={{ p: 1 }}>
        <IconBoxesStacked />
      </Box>
      <Box flexGrow={1}>
        <Typography>{t("components.ModuleGroupSummary.title")}</Typography>
        <Typography>
          {t("components.ModuleGroupSummary.modules", {
            modules: modulesCount,
          })}
        </Typography>
      </Box>
      {editable && (
        <Box sx={{ p: 1 }}>
          <IconButton
            size="small"
            onClick={() => {
              navigate({
                to: "/pickup-orders/$orderId/$stepId/groups/$groupId/packages/$packageId/edit",
                params: {
                  stepId: stepId,
                  orderId: order.id,
                  groupId: boxes[0].group as string,
                  packageId: boxes[0].id,
                },
              })
            }}
          >
            <IconArrowRight />
          </IconButton>
        </Box>
      )}
      {editable && (
        <Box>
          <IconButton
            size="small"
            onClick={() => {
              deleteGroup({
                variables: {
                  input: {
                    etag: order.etag,
                    pickupOrderId: order.id,
                    groupId: boxes[0].group,
                  },
                },
              })
            }}
          >
            <IconTrashCan />
          </IconButton>
        </Box>
      )}
    </PackCard>
  )
}

const PackCard = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
