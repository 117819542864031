/* eslint-disable react/jsx-props-no-spreading */
import { BoxTypeFragment, ModuleTypeFragment, OrderFragment, PackTypeFragment } from "@gen/graphql"
import { zodResolver } from "@hookform/resolvers/zod"
import { z } from "@lib/i18n"
import { FormControlLabel, FormLabel, InputAdornment } from "@mui/material"
import { Box, Card, CardContent, Radio, RadioGroup, Stack, Typography } from "@northvolt/ui"
import { AutoCompleteInput, IconBox } from "@shared"
import { JSX, useMemo } from "react"
import { Controller, DefaultValues, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { AddModule } from "./AddModule"
import { AddPack } from "./AddPack"

const packSchema = z.object({
  boxTypeId: z.string(),
  boxType: z.enum(["pack", "module", "unknown"]),
})
export type ZodInputTypes = z.infer<typeof packSchema>

type AddBoxProps = {
  setAdding: (state: boolean) => void
  order: OrderFragment
  boxTypes: BoxTypeFragment[]
  moduleTypes: ModuleTypeFragment[]
  packTypes: PackTypeFragment[]
}

/**
 * ## AddBoxStep - AddBox
 * Part of the AddBoxStep flow, this component is responsible for rendering the form to add a new box, including packs or modules, to the order.
 */
export const AddBox = ({
  setAdding,
  order,
  boxTypes,
  moduleTypes,
  packTypes,
}: AddBoxProps): JSX.Element => {
  const { t } = useTranslation()
  const defaultValues: Required<DefaultValues<ZodInputTypes>> = {
    boxTypeId: "",
    boxType: "unknown",
  }
  const methods = useForm<ZodInputTypes>({
    resolver: zodResolver(packSchema),
    mode: "onBlur",
    defaultValues,
  })
  const { control, watch } = methods
  const watchBoxTypeId = watch("boxTypeId")
  const watchBoxType = watch("boxType")

  const selectedBoxType = useMemo(() => {
    return boxTypes.find((p) => p.id === watchBoxTypeId)
  }, [boxTypes, watchBoxTypeId])

  return (
    <Card>
      <CardContent>
        <Stack spacing={1}>
          <Typography fontSize={16} fontWeight={700} component="h2" sx={{ mb: 2 }}>
            {t("components.AddBox.title")}
          </Typography>
          <AutoCompleteInput
            control={control}
            fullWidth
            name="boxTypeId"
            defaultValue=""
            // disablePortal makes it so the autocomplete dropdown appears within the react part of the DOM. Important to ensure tests work correctly
            disablePortal={true}
            label={t("components.basics.selectBoxType.label")}
            endAdornment={
              <InputAdornment position="start">
                <IconBox />
              </InputAdornment>
            }
            options={boxTypes.map((p) => ({
              value: p.id,
              label: p.displayName,
            }))}
          />
        </Stack>
        {watchBoxTypeId !== "" && (
          <Stack marginTop={4} gap={4}>
            <Box>
              <FormLabel>{t("components.AddBox.selectPackOrModule.label")}</FormLabel>
              <Controller
                rules={{ required: true }}
                defaultValue={undefined}
                control={control}
                name="boxType"
                render={({ field }) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <RadioGroup row {...field}>
                    <FormControlLabel
                      value="pack"
                      control={<Radio />}
                      label={t("components.AddBox.selectPackOrModule.packs")}
                    />
                    <FormControlLabel
                      value="module"
                      control={<Radio />}
                      label={t("components.AddBox.selectPackOrModule.modules")}
                    />
                  </RadioGroup>
                )}
              />
            </Box>
          </Stack>
        )}
      </CardContent>
      {selectedBoxType && watchBoxType === "pack" && (
        <AddPack
          order={order}
          packTypes={packTypes}
          selectedBoxType={selectedBoxType}
          setAdding={setAdding}
        />
      )}
      {selectedBoxType && watchBoxType === "module" && (
        <AddModule
          order={order}
          moduleTypes={moduleTypes}
          selectedBoxType={selectedBoxType}
          setAdding={setAdding}
        />
      )}
    </Card>
  )
}
