import { NVThemeProvider } from "@northvolt/ui"
import { NVLocalizationProviderDayjs } from "@northvolt/ui/Dayjs"
import { ApolloProvider, AuthProvider } from "@providers"
import { MissingRole } from "@shared"
import { RouterProvider, createRouter } from "@tanstack/react-router"
import { JSX } from "react"
import { ErrorBoundary } from "react-error-boundary"
import { useLocalStorage } from "usehooks-ts"
import { routeTree } from "./gen/routeTree.gen"
import { config } from "./lib/config"
import { i18n } from "./lib/i18n"

// Create a new router instance
const router = createRouter({ routeTree })

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router
  }
}

export const App = (): JSX.Element => {
  const [currentLanguage] = useLocalStorage("language", "en-GB")

  // Change language to english
  i18n.changeLanguage(currentLanguage)
  let dayJSLocale = "en-gb"
  switch (i18n.language) {
    case "de-DE":
      import("dayjs/locale/de")
      dayJSLocale = "de"
      break
    default:
      import("dayjs/locale/en-gb")
  }

  return (
    <ErrorBoundary fallbackRender={fallbackRender}>
      <AuthProvider
        approvedRoles={config.approvedRoles}
        redirectURL={config.redirectURL}
        cookieBaseName={config.cookieBaseName}
        cognitoUserPoolWebClientId={config.cognito.userPoolWebClientId}
        cognitoOauthUri={config.cognito.oauthUri}
        loadingComponent={<div></div>}
        onErrorComponent={<div>Couldn't authenticate</div>}
        onMissingRoleComponent={<MissingRole />}
      >
        <ApolloProvider config={config}>
          <NVThemeProvider useSnackbar usePreferredTheme brand="revolt">
            <NVLocalizationProviderDayjs adapterLocale={dayJSLocale}>
              <RouterProvider router={router} />
            </NVLocalizationProviderDayjs>
          </NVThemeProvider>
        </ApolloProvider>
      </AuthProvider>
    </ErrorBoundary>
  )
}

function fallbackRender(): JSX.Element {
  // TODO pass the error to sentry so that we can trigger an alert on it
  // fallbackRender takes { error } as an argument
  return (
    <div role="alert">
      <h1>Something went wrong</h1>
      <p>But we're working on fixing it!</p>
    </div>
  )
}
