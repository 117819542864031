import { OrderFragment } from "@gen/graphql"
import { Alert, Box, Button, Card, Grid, Modal, Typography } from "@northvolt/ui"
import { IconCheckmark } from "@shared"
import { useNavigate } from "@tanstack/react-router"
import { JSX } from "react"
import { useTranslation } from "react-i18next"

type SubmittedModalProps = {
  order: OrderFragment
}

export const SubmittedModal = ({ order }: SubmittedModalProps): JSX.Element => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <Modal
      open={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card
        sx={{
          width: "500px",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          boxShadow: 24,
          p: 4,
          border: "none",
          outline: "none",
        }}
      >
        <Typography sx={{ textAlign: "center", fontSize: "64px" }}>
          <IconCheckmark />
        </Typography>
        <Typography sx={{ textAlign: "center" }} variant="headlineMedium" mb={4}>
          {t("components.SubmittedModal.title")}
        </Typography>
        <Typography mb={2}>{t("components.SubmittedModal.body")}</Typography>
        <Box mb={2}>
          <Alert severity="info">
            <Grid container spacing={1}>
              <Grid sm={12} md={9}>
                {t("components.SubmittedModal.alert")}
              </Grid>
              <Grid sm={12} md={3} sx={{ textAlign: "right" }}>
                <Button
                  variant="text"
                  color="info"
                  fullWidth
                  onClick={() => {
                    navigate({
                      to: "/pickup-orders/$orderId/$stepId",
                      params: {
                        orderId: order.id,
                        stepId: "1",
                      },
                    })
                  }}
                >
                  {t("components.SubmittedModal.action_edit")}
                </Button>
              </Grid>
            </Grid>
          </Alert>
        </Box>
        <Box mb={2}>
          <Button
            variant="text"
            color="secondary"
            fullWidth
            onClick={() => {
              navigate({
                to: "/pickup-orders",
              })
            }}
          >
            {t("components.SubmittedModal.action_create")}
          </Button>
        </Box>
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          onClick={() => {
            navigate({
              to: "/overview/$orderId",
              params: {
                orderId: order.id,
              },
            })
          }}
        >
          {t("components.SubmittedModal.action_followup")}
        </Button>
      </Card>
    </Modal>
  )
}
