import { OrderFragment, OrderStatus } from "@gen/graphql"
import { CardHeader } from "@mui/material"
import { Box, Button, Card, CardContent, Stack, Typography } from "@northvolt/ui"
import { useNavigate } from "@tanstack/react-router"
import { JSX, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { ListOrderBoxes } from "../ListOrderBoxes/ListOrderBoxes"

type OrderSummaryProps = {
  stepId: string
  order: OrderFragment | null
  submit?: () => void
}

export const OrderSummary = ({ stepId, order, submit }: OrderSummaryProps): JSX.Element => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const haveBoxes = (order?.boxes.length ?? 0) > 0
  const grossWeight = useMemo(() => {
    return order?.boxes.reduce((acc, p) => {
      let packageWeight = 0
      p.items.forEach((c) => {
        if (c.__typename === "Pack") {
          packageWeight += c.netWeight
        }
        if (c.__typename === "Module") {
          packageWeight += c.netWeight * c.green + c.netWeight * c.yellow + c.netWeight * c.red
        }
      })
      return acc + packageWeight + p.boxType.netWeight
    }, 0)
  }, [order?.boxes])

  // TODO: Here we need to do the same with modules as well

  return (
    <Card>
      <CardHeader title={t("components.OrderSummary.title")} />
      <CardContent>
        <Stack spacing={1}>
          {order && (
            <Box
              sx={{
                border: 1,
                borderColor: "grey.700",
                borderRadius: 1,
                padding: 2,
              }}
            >
              <Typography>
                {t("components.OrderSummary.pickupDate", {
                  date: order.pickupDate,
                })}
              </Typography>
              <Typography>
                {t("components.OrderSummary.pickupLocation", {
                  location: order.pickupLocation?.displayName,
                })}
              </Typography>
            </Box>
          )}
          {!haveBoxes && <Typography>{t("components.OrderSummary.emptyOrder")}</Typography>}
          {haveBoxes && (
            <>
              <Typography fontSize={16} component="h2" sx={{ mt: 2 }}>
                <Stack direction="row" gap={5}>
                  <Stack direction="row" gap={1} alignItems="center">
                    <Typography>{t("components.OrderSummary.totalPackages")}</Typography>
                    <Typography variant="headlineSmall">{order?.boxes.length}</Typography>
                  </Stack>
                  <Stack direction="row" gap={1} alignItems="center">
                    <Typography>{t("components.OrderSummary.grossWeight")}</Typography>
                    <Typography variant="headlineSmall">{grossWeight} kg</Typography>
                  </Stack>
                </Stack>
              </Typography>
              {order && (
                <ListOrderBoxes
                  order={order}
                  stepId={stepId}
                  editable={
                    order.status === OrderStatus.Draft || order.status === OrderStatus.Pending
                  }
                />
              )}
            </>
          )}
          {submit && haveBoxes && (
            <Button
              sx={{ marginTop: 5 }}
              onClick={submit}
              disabled={
                order?.status === OrderStatus.Approved || order?.status === OrderStatus.Arrived
              }
            >
              <>
                {order?.status === OrderStatus.Draft && t("components.OrderSummary.submit")}
                {order?.status !== OrderStatus.Draft && t("components.OrderSummary.resubmit")}
              </>
            </Button>
          )}
          {submit && haveBoxes && (
            <Button
              variant="text"
              color="secondary"
              onClick={() => {
                navigate({
                  to: "/overview",
                })
              }}
            >
              Cancel
            </Button>
          )}
        </Stack>
      </CardContent>
    </Card>
  )
}
